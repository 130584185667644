export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavItem',
    name: 'Domains',
    to: '/domains',
    icon: 'cil-globe-alt',
  },
  {
    component: 'CNavGroup',
    name: 'Leads',
    icon: 'cil-gem',
    items: [
      {
        component: 'CNavItem',
        name: 'Requests Logs',
        to: '/leads/requests',
        icon: 'cil-description',
      },
      {
        component: 'CNavItem',
        name: 'Lead Status',
        to: '/leads/status',
        icon: 'cil-tag',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Handlers',
    icon: 'cil-contact',
    items: [
      {
        component: 'CNavItem',
        name: 'Affiliates',
        to: '/handlers/affiliates',
        icon: 'cil-people',
      },
      {
        component: 'CNavItem',
        name: 'Offers',
        to: '/handlers/offers',
        icon: 'cil-address-book',
      },
      {
        component: 'CNavItem',
        name: 'Postback URLs',
        to: '/handlers/postbacks',
        icon: 'cil-external-link',
      },
      {
        component: 'CNavItem',
        name: 'Existing URLs',
        to: '/handlers/existing-urls',
        icon: 'cil-https',
      },
      {
        component: 'CNavItem',
        name: 'URL Builder',
        to: '/handlers/urlbuilder',
        icon: 'cil-link',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Conversions',
    icon: 'cil-transfer',
    items: [
      {
        component: 'CNavItem',
        name: 'Conversion Report',
        to: '/conversions/report',
        icon: 'cil-columns',
      },
      {
        component: 'CNavItem',
        name: 'Group Reports',
        to: '/conversions/group-reports',
        icon: 'cil-book',
      },
      {
        component: 'CNavItem',
        name: 'Custom Group Reports',
        to: '/conversions/custom-group-reports',
        icon: 'cil-casino',
      },
      {
        component: 'CNavItem',
        name: 'Endpoint Builder',
        to: '/conversions/receive',
        icon: 'cil-link',
      },
      {
        component: 'CNavItem',
        name: 'Revenue Counter',
        to: '/conversions/revenue-counter',
        icon: 'cil-life-ring',
      },
      {
        component: 'CNavItem',
        name: 'Click Logs',
        to: '/conversions/click-logs',
        icon: 'cil-hand-point-up',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'API Conversions',
    icon: 'cil-font',
    items: [
      {
        component: 'CNavItem',
        name: 'API Conversion Report',
        to: '/conversions/api/report',
        icon: 'cil-envelope-letter',
      },
      {
        component: 'CNavItem',
        name: 'API Group Reports',
        to: '/conversions/api/group-reports',
        icon: 'cil-contact',
      },
      {
        component: 'CNavItem',
        name: 'API Endpoint Builder',
        to: '/conversions/api/receive',
        icon: 'cil-devices',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Statistics',
    icon: 'cil-bar-chart',
    items: [
      {
        component: 'CNavItem',
        name: 'Daily Leads',
        to: '/statistics/leads/daily',
        icon: 'cil-cloudy',
      },
      {
        component: 'CNavItem',
        name: 'API Status Report',
        to: '/statistics/api/status',
        icon: 'cil-bolt',
      },
      {
        component: 'CNavItem',
        name: 'Lead Acceptance',
        to: '/statistics/leads/acceptance',
        icon: 'cil-envelope-open',
      },
      {
        component: 'CNavItem',
        name: 'Time Monitoring',
        to: '/statistics/monitoring/time',
        icon: 'cil-av-timer',
      },
      {
        component: 'CNavItem',
        name: 'Leads Monitoring',
        to: '/statistics/monitoring/leads',
        icon: 'cil-album',
      },
      {
        component: 'CNavItem',
        name: 'Revenue Monitoring',
        to: '/statistics/monitoring/revenue',
        icon: 'cil-bank',
      },
      {
        component: 'CNavItem',
        name: 'RPL Monitoring',
        to: '/statistics/monitoring/rpl',
        icon: 'cil-badge',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'SMS Center',
    icon: 'cil-phone',
    items: [
      {
        component: 'CNavItem',
        name: 'SMS Campaigns',
        to: '/sms-center/sms-campaigns',
        icon: 'cil-bullhorn',
      },
      {
        component: 'CNavItem',
        name: 'History Logs',
        to: '/sms-center/history-logs',
        icon: 'cil-transfer',
      },
      {
        component: 'CNavItem',
        name: 'Tracking Logs',
        to: '/sms-center/tracking-logs',
        icon: 'cil-mobile',
      },
      {
        component: 'CNavItem',
        name: 'SMS Settings',
        to: '/sms-center/sms-settings',
        icon: 'cil-mobile-landscape',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Publisher API',
    icon: 'cil-sitemap',
    items: [
      {
        component: 'CNavItem',
        name: 'Partner Registration',
        to: '/partners/registration',
        icon: 'cil-user',
      },
      {
        component: 'CNavItem',
        name: 'Revenue',
        to: '/partners/revenue',
        icon: 'cil-money',
      },
      {
        component: 'CNavItem',
        name: 'Lead Payouts',
        to: '/partners/lead-statistics',
        icon: 'cil-basket',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Applications',
    icon: 'cil-laptop',
    items: [
      {
        component: 'CNavItem',
        name: 'API Tree',
        to: '/applications/pingtree',
        icon: 'cil-lan',
      },
      {
        component: 'CNavItem',
        name: 'Fields',
        to: '/applications/fields',
        icon: 'cil-object-group',
      },
      {
        component: 'CNavItem',
        name: 'Wall of Offers',
        to: '/applications/wall-of-offers',
        icon: 'cil-window',
      },
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Additional',
  },
  {
    component: 'CNavItem',
    name: 'Countries',
    to: '/settings/countries',
    icon: 'cil-flag-alt',
  },
  {
    component: 'CNavItem',
    name: 'Accounts',
    to: '/settings/accounts',
    icon: 'cil-user-plus',
  },
  {
    component: 'CNavItem',
    name: 'Meta Reporting',
    to: '/settings/meta-reporting',
    icon: 'cil-flight-takeoff',
  },
  {
    component: 'CNavItem',
    name: 'API Documentation',
    to: '/documentation',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Logout',
    to: '/logout',
    icon: 'cil-account-logout',
  },
]
