import metaReporting from '@/api/metaReporting'
import helpers from '@/utils/helpers'

const state = {
  reportings: [],
  countries: [],
  loading: false,
  error: null,
  tab: 'spain',
}

const mutations = {
  SET_REPORTINGS(state, reportings) {
    state.reportings = reportings
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  SET_TAB(state, tab) {
    state.tab = tab
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  INSERT_REPORTING(state, reporting) {
    const country = reporting.country
    state.reportings[country] = [].concat(state.reportings[country], reporting)
  },
  UPDATE_REPORTING(state, reporting) {
    const reportings = state.reportings[state.tab]
    const index = reportings.findIndex((item) => item.id === reporting.id)
    state.reportings[state.tab][index] = reporting
  },
  UPDATE_REPORTING_STATUS(state, reporting) {
    const reportings = state.reportings[state.tab]
    const index = reportings.findIndex((item) => item.id === reporting.id)
    state.reportings[state.tab][index].active = reporting.active
  },
}

const actions = {
  setCurrentTab({ commit }, payload) {
    commit('SET_TAB', payload)
  },

  async fetchMetaReportings({ commit }) {
    commit('SET_LOADING', true)
    return await metaReporting
      .fetchMetaReportings()
      .then((response) => {
        commit('SET_REPORTINGS', response.data.data?.data)
        commit('SET_COUNTRIES', response.data.data?.countries)
        commit('SET_LOADING', false)
        return response.data.data?.data
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
        commit('SET_LOADING', false)
      })
  },

  async addReporting({ commit }, payload) {
    return await metaReporting
      .addReporting(payload)
      .then((response) => {
        commit('INSERT_REPORTING', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateReporting({ commit }, { id, payload }) {
    return await metaReporting
      .updateReporting(id, payload)
      .then((response) => {
        commit('UPDATE_REPORTING', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },

  async updateStatus({ commit }, { id, status }) {
    return await metaReporting
      .updateStatus({ id, status })
      .then((response) => {
        commit('UPDATE_REPORTING_STATUS', response.data.data)
      })
      .catch((error) => {
        commit('SET_ERROR', helpers.getError(error))
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
