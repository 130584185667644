import axiosAPI from '@/api/axios'

export default {
  async fetchMetaReportings() {
    return await axiosAPI.get('/api/meta-reporting')
  },

  async addReporting(payload) {
    return await axiosAPI.post('/api/meta-reporting', payload)
  },

  async updateReporting(id, payload) {
    return await axiosAPI.post(`/api/meta-reporting/${id}`, payload)
  },

  async updateStatus({ id, active }) {
    return await axiosAPI.post(`/api/meta-reporting/${id}/status`, {
      active: active,
    })
  },
}
